<template>
  <section>
    <VCard class="selection-card elevation-20">
      <VCardTitle>Confirm Deletion</VCardTitle>
      <VCardText>
        <p>This action is permanent and irreversible. Are you sure you want to delete this?</p>
      </VCardText>
      <VCardActions>
        <VSpacer />
        <VBtn
          flat
          round
          color="primary"
          @click="close()"
        >
          Cancel
        </VBtn>
        <VBtn
          flat
          round
          color="primary"
          @click="confirmed()"
        >
          Delete
        </VBtn>
      </VCardActions>
    </VCard>
  </section>
</template>

<script>
export default {
  methods: {
    confirmed() {
      this.$emit('delete');
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
.selection-card {
  padding: 1.5rem;
  border-radius: 8px !important;

  .v-card__title {
    font-size: 1.5rem;
    padding: 0;
    margin: 0 0 0.5rem 0;
  }

  .v-card__text {
    font-size: 1rem;
    padding: 0;

    p {
      margin: 0;
    }
  }

  .v-card__actions {
    padding: 0;
    margin: 1.5rem 0 0 0;
  }
}
</style>
