!<template>
  <section>
    <section class="top">
      <div>
        <p class="note_title">
          Note
        </p>
        <p class="note_subtitle">
          List Of Notes
        </p>
      </div>

      <div>
        <div class="top_search">
          <VTextField
            v-model="search"
            :hide-details="true"
            label="Search Note By Title"
            append-icon="search"
            solo
            flat
          />
        </div>
      </div>
      <div class="top_btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          to="note/new"
        >
          New Note
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="note_grid">
        <div
          v-for="(note, index) in filteredNote"
          :key="index"
        >
          <VCard class="note_card shadow">
            <p class="note_main_title">
              {{ note.title }}
            </p>
            <p class="note_main_subtitle">
              {{ note.date }}
            </p>
            <p class="note_main_subtitle">
              CL: {{ note.class }}
            </p>
            <p class="note_main_txt">
              {{ note.snippet }}
            </p>
            <VCardActions class="mt-3 pa-0">
              <VBtn
                small
                icon
                color="primary"
                class="ma-0"
                @click="openDialog(index)"
              >
                <VIcon small>
                  delete
                </VIcon>
              </VBtn>
              <VSpacer />
              <VBtn
                color="primary"
                class="ma-0"
                flat
                round
                @click="readMore(note.id)"
              >
                Read More
              </VBtn>
            </VCardActions>
          </VCard>
        </div>
      </div>
    </section>
    <section>
      <VDialog
        v-model="confDel"
        persistent
        width="300"
      >
        <ConfirmDelete
          @close="confDel = false"
          @delete="postDelete(selectedPost)"
        />
      </VDialog>
      <VDialog
        v-model="isLoading"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'loading'"
          class="notification_card elevation-20"
        >
          <p>Loading</p>
          <span>Fetching Data, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
      <VDialog
        v-model="isDeleting"
        width="300"
        persistent
      >
        <VCard
          v-if="loadStatus === 'deleting'"
          class="notification_card elevation-20"
        >
          <p>Deleting</p>
          <span>Deleting Note, Please Wait...</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="primary"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'netfail'"
          class="notification_card elevation-20"
        >
          <p>Internet Issue</p>
          <span>Retrying In {{ retry }} Seconds</span>
          <div class="notification_ico">
            <VProgressCircular
              indeterminate
              color="red"
              :size="30"
              :width="3"
            />
          </div>
        </VCard>
        <VCard
          v-if="loadStatus === 'fail'"
          class="notification_card elevation-20"
        >
          <p>Error</p>
          <span>An Error Occured</span>
          <div class="notification_ico">
            <VBtn
              depressed
              round
              color="primary"
              @click="reloadPage()"
            >
              Reload
            </VBtn>
          </div>
        </VCard>
      </VDialog>
    </section>
  </section>
</template>

<script>
import axios from 'axios';
import firebase from '../../conf/firebaseAuth';
import api from '../../conf/api';
import ConfirmDelete from '../../components/confirmDelete.vue';

export default {
  components: {
    ConfirmDelete,
  },
  data: () => ({
    isLoading: true,
    isDeleting: false,
    loadStatus: '',
    retry: 0,
    confDel: false,
    search: '',
    notes: [],
  }),
  computed: {
    filteredNote() {
      return this.notes.filter(note => note.title.toLowerCase()
        .match(this.search.toLowerCase()));
    },
  },
  async beforeMount() {
    try {
      this.loadStatus = 'loading';
      await axios.get(`${api}/note/all`)
        .then((res) => {
          this.isLoading = false;
          if (res.data !== '') {
            this.notes = res.data.data;
          }
        });
    } catch (e) {
      if (e.code === 'auth/network-request-failed') {
        this.loadStatus = 'netfail';
        this.retry = 10;
        setInterval(() => {
          this.retry -= 1;
        }, 1000);
        setTimeout(() => {
          window.location.reload();
        }, 9500);
      } else {
        this.loadStatus = 'fail';
      }
    }
  },

  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    readMore(id) {
      setTimeout(() => {
        this.$router.push(`/note/${id}`);
      }, 150);
    },
    postDelete(v) {
      this.isDeleting = true;
      this.loadStatus = 'deleting';
      const { id } = this.notes[v];
      axios.delete(`${api}/note/del/${id}`).then(() => {
        this.confDel = false;
        this.isDeleting = false;
        const noteImgUrls = this.notes[v].imgUrls;
        if (noteImgUrls.length !== 0) {
          noteImgUrls.forEach((url) => {
            firebase.storage().refFromURL(url).delete();
          });
        }
        const newNotes = this.notes.slice(0, v).concat(this.notes.slice(v + 1, this.notes.length));
        this.notes = newNotes;
      }).catch((e) => {
        if (e.code === 'auth/network-request-failed') {
          this.loadStatus = 'netfail';
          this.retry = 10;
          setInterval(() => {
            this.retry -= 1;
          }, 1000);
          setTimeout(() => {
            window.location.reload();
          }, 9500);
        } else {
          this.loadStatus = 'fail';
        }
      });
    },
    openDialog(id) {
      this.selectedPost = id;
      this.confDel = true;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/Note/index';
</style>
